import slick from 'slick-carousel'
import Foundation from 'foundation-sites'
import AOS from 'aos'
import axios from 'axios'
import Turbolinks from 'turbolinks'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

function AOSinit() {
  AOS.init({
    duration: 500
  })
}

Turbolinks.start()

require('flatpickr')
require('flatpickr/dist/l10n/pt.js')
require('jquery-mask-plugin')
require('@fancyapps/fancybox')

/** Google Analytics */
// require('./gtag')
// window.dataLayer = window.dataLayer || []
// function gtag() {
//   dataLayer.push(arguments)
// }

$(document).foundation()
$(document).on('turbolinks:load', function() {
  turbolinksOnLoad()
})
$(document).on('turbolinks:before-cache', function() {
  // $('.slider').slick('unslick')
})

/**
 *
 * on document READY
 *
 */
$(document).ready(function() {
  AOSinit()
  $.fancybox.defaults.hash = false
})

/**
 * Functions that need to reload on each turbolinks:load
 */
function turbolinksOnLoad() {
  $(document).foundation()
  iniSlider()
  floatLabels()
  stickTopbar()
  resizeMap()
  AOSinit()
  checkFormErrors()
  disableForm()
  updateSlideHeight()

  /** Google Analytics */
  // gtag('js', new Date())
  // gtag('config', 'UA-65076305-2', {
  //   page_title: event.target.title,
  //   page_path: event.data.url.replace(
  //     window.location.protocol + '//' + window.location.hostname,
  //     ''
  //   )
  // })
  /** // */
  $('#id_resign_contract').change(function() {
    if ($(this).val() !== '') {
      $(this).addClass('loading')
      let data = {
        composed_number: $(this).val()
      }
      axios
        .post('/resign-companies/', data)
        .then(res => {
          $(
            `#id_resign_contract_company option[value="${res.data.company}"]`
          ).attr('selected', true)
          $(`#id_resign_contract_company`)
            .closest('.form-control')
            .removeClass('error')
            .find('.helper-text')
            .text('')
        })
        .catch(error => {
          $(`#id_resign_contract_company option`).removeAttr('selected')
          $(`#id_resign_contract_company`)
            .closest('.form-control')
            .addClass('error')
            .find('.helper-text')
            .text(error.response.data.company)
        })
        .finally(function() {
          $(this).removeClass('loading')
        })
    } else {
      $(`#id_resign_contract_company option`).removeAttr('selected')
    }
  })

  $('form')
    .not('.noajax')
    .submit(function(e) {
      e.preventDefault()
      let form = $(this)
      let submitButton = form.find('button[type="submit"]')
      let submitButtonText = submitButton.text()
      let formSerialized = form.serializeArray()
      let formData = new FormData()
      let fileInputs = document.querySelector('input[type=file]')
      $('input[type=file]').each(function() {
        if ($(this).prop('files')[0])
          formData.append($(this).prop('name'), $(this).prop('files')[0])
      })
      formSerialized.forEach(item =>
        formData.append(item['name'], item['value'])
      )
      form.addClass('loading')
      form
        .find('button[type="submit"]')
        .prop('disabled', true)
        .text('Enviando...')
      form.find('.helper-text').text('')
      form.find('.form-control').removeClass('error')
      form
        .find('.form-response')
        .removeClass('success')
        .removeClass('error')
        .text('')
        .slideUp('fast')
      axios
        .post(form.attr('action'), formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => {
          form
            .find('button[type="submit"]')
            .prop('disabled', false)
            .text('Enviar')
          form
            .find('.form-response')
            .addClass('success')
            .text('Enviado com sucesso!')
            .slideDown()
          form
            .find('input', 'textarea')
            .not("input[type='hidden']")
            .val('')
        })
        .catch(error => {
          if (error.response.data)
            for (let [key, val] of Object.entries(error.response.data)) {
              let input = form.find(`[name='${key}']`)
              if (input.length) {
                input
                  .closest('.form-control')
                  .addClass('error')
                  .find('.helper-text')
                  .text(val)
              } else {
                form
                  .find('.form-response')
                  .addClass('error')
                  .slideDown()
                  .text(val)
              }
            }
          submitButton.prop('disabled', false).text(submitButtonText)
        })
        .finally(function() {
          form.removeClass('loading')
        })
    })

  /**
   * Masks
   */
  $('.nif').mask('000.000.000')
  $('.money').mask('#.##0,00', { reverse: true })
  $('.date_input').flatpickr({
    locale: 'pt',
    dateFormat: 'd/m/Y'
  })

  /**
   * Utils
   */
  $('.openMenu').click(() => {
    openMenu()
  })

  $('.openMenuSearch').click(() => {
    openMenu(true, true)
  })

  $('.close').click(function() {
    $(this)
      .closest('.showing')
      .removeClass('showing')
  })

  $('.toggleFS').click(function(e) {
    toggleFS(e)
  })

  $('[data-submit-alert]').click(function(e) {
    $(this).addClass('loading')
    let status = $(this).attr('value')
    const msgs = {
      active:
        'Confirma envio para impressão? Após o envio não será mais possível editar.',
      canceled: 'Confirma o cancelamento?',
      deleted: 'Confirma a exclusão?'
    }
    if (!confirm(msgs[status])) {
      e.preventDefault()
      $(this).removeClass('loading')
      return
    }

    $(this)
      .parents('form')
      .append(
        '<input type="hidden" name="' +
          $(this).attr('name') +
          '" value="' +
          $(this).attr('value') +
          '" />'
      )
      .submit()
  })

  /**
   * Installments
   */
  $('.add-installment').click(function(e) {
    $(this)
      .parents('.installments')
      .find('.installment.hidden')
      .first()
      .removeClass('hidden')
      .find('input[type=hidden]')
      .prop('value', false)
    if (!$('.installment').hasClass('hidden')) $(this).addClass('hidden')
    floatLabels()
  })
  $('.remove-installment').click(function(e) {
    $(this)
      .parents('.installment')
      .addClass('hidden')
      .find('input[type=hidden]')
      .prop('value', true)
    $('.add-installment').removeClass('hidden')
  })
}

/**
 * --------------------------------------------------
 * Window scroll functions
 * --------------------------------------------------
 */

$(window).scroll(function() {
  stickTopbar()
})
/**
 * --------------------------------------------------
 * Window resize
 * --------------------------------------------------
 */

$(window).resize(() => {
  updateSlideHeight()
})

/**
 * --------------------------------------------------
 * Slick slider
 * --------------------------------------------------
 */
function iniSlider() {
  if ($('.slider')) {
    $(document).on('keydown', function(e) {
      if (e.keyCode == 37) {
        $('.slider').slick('slickPrev')
      }
      if (e.keyCode == 39) {
        $('.slider').slick('slickNext')
      }
    })
    $('.slider')
      .not('.home')
      .slick({
        arrows: false,
        rows: 0, // prevents adding a wrapper div
        autoplay: true,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: true
            }
          }
        ]
      })
    $('.slider.home').slick({
      arrows: false,
      rows: 0, // prevents adding a wrapper div
      autoplay: true
    })
  }
}

function toggleFS(e) {
  let el = $(e.target)
    .parents('.fullScreen')
    .first()
  if (el.hasClass('on')) {
    el.removeClass('on')
  } else {
    el.addClass('on')
  }
  // if (elem) {
  //   console.log(elem)
  //   if (elem.requestFullscreen) {
  //     elem.requestFullscreen()
  //   } else if (elem.mozRequestFullScreen) {
  //     /* Firefox */
  //     elem.mozRequestFullScreen()
  //   } else if (elem.webkitRequestFullscreen) {
  //     /* Chrome, Safari and Opera */
  //     elem.webkitRequestFullscreen()
  //   } else if (elem.msRequestFullscreen) {
  //     /* IE/Edge */
  //     elem.msRequestFullscreen()
  //   }
  // }
}
function closeFS() {
  $('.fullScreen').removeClass('on')
  // if (document.exitFullscreen) {
  //   document.exitFullscreen()
  // } else if (document.mozCancelFullScreen) {
  //   /* Firefox */
  //   document.mozCancelFullScreen()
  // } else if (document.webkitExitFullscreen) {
  //   /* Chrome, Safari and Opera */
  //   document.webkitExitFullscreen()
  // } else if (document.msExitFullscreen) {
  //   /* IE/Edge */
  //   document.msExitFullscreen()
  // }
}

function updateSlideHeight() {
  if ($('.slider'))
    $('.slider.fullScreen .slide').height(
      $(window).height() -
        $('.topbar').outerHeight() -
        $('.footer').outerHeight()
    )
}

/**
 * --------------------------------------------------
 * Map
 * --------------------------------------------------
 */
function resizeMap() {
  let map = $('.map.map--fullscreen')
  if (map) {
    map.height(
      map.height() - $('.topbar').outerHeight() - $('.footer').outerHeight()
    )
  }
}
/**
 * --------------------------------------------------
 * Stick topbar
 * --------------------------------------------------
 */
function stickTopbar() {
  let pos = $(window).scrollTop(),
    topbar = $('.topbar')
  if (pos > topbar.outerHeight()) {
    topbar.addClass('sticky')
  } else {
    topbar.removeClass('sticky')
  }
}

/**
 * --------------------------------------------------
 * Menu
 * --------------------------------------------------
 */

function openMenu(opened = true, focusSearch = false) {
  if (opened) {
    $('.menu').addClass('showing')
  } else {
    $('.menu').removeClass('showing')
  }
  if (focusSearch)
    setTimeout(() => {
      $(".menu__search input[name='s']").focus()
    }, 200)
}

/**
 * --------------------------------------------------
 * FLOAT LABES
 * --------------------------------------------------
 * Function to transform labels like placeholders
 * and floats to top when focused or filled
 *
 */

function floatLabels() {
  $('.form-control').each(function() {
    var formGroup = $(this),
      field = formGroup
        .children('input, textarea', 'select')
        .first()
        .not(':hidden')

    // check empty fields
    formGroup.toggleClass('is-filled', !isEmptyField(field))

    // on form-control click focus on field
    formGroup.click(function() {
      if (!field.is(':focus')) {
        field.focus()
      }
    })

    // if field has autofocus attribute
    if (field.attr('autofocus')) {
      formGroup.addClass('is-active')
    }

    // focus & blur
    field.focusin(function() {
      formGroup.addClass('is-active')
    })
    field.blur(function() {
      formGroup.removeClass('is-active')
      if (isEmptyField(field) === true) {
        formGroup.removeClass('is-filled')
      } else {
        formGroup.addClass('is-filled')
      }
    })
  })
}

function isEmptyField(el) {
  if (el.attr('type') === 'radio') return !el.is(':checked')
  return !el.val()
}

/**
 * Function to check errorlist provided from django and add class to form-control
 */
function checkFormErrors() {
  $('.errorlist')
    .parents('.form-control')
    .addClass('error')
}

/**
 * Function to disable all fields in a form with the class "disabled"
 */
function disableForm() {
  $('form').each(function() {
    if ($(this).hasClass('disabled'))
      $(this)
        .find('input, textarea, select, radio, checkbox')
        .prop('disabled', true)
  })
}

function checkErrorInputs() {
  var el, firstError, scrollTarget
  $('.form-control').each(function() {
    el = $(this)
    el.children('.form-error-list').each(function() {
      el.addClass('is-invalid-input')
    })
  })

  firstError = $('.is-invalid-input').first()

  if (firstError.length > 0) {
    setTimeout(function() {
      $('html, body').animate(
        {
          scrollTop: (firstError.offset().top -= $(
            'body>header>nav'
          ).outerHeight())
        },
        800
      )
    }, 300)
  }
}
